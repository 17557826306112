<template>
  <div class="clue">
    <div class="container">
      <!-- 顶部卡片 -->
      <el-card class="top_card">
        <el-form ref="form" :model="queryInfo" label-width="120">
          <el-form-item label="匹配时间：">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="queryInfo.date"
              @change="checkParamsData"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="匹配分数：">
            <div class="input_flex">
              <el-input
                style="width: 160px"
                placeholder="请输入分数"
                v-model="queryInfo.input"
              ></el-input
              >－
              <el-input
                style="width: 160px"
                placeholder="请输入分数"
                v-model="queryInfo.input"
              ></el-input>
            </div>
          </el-form-item>

          <el-form-item label="所在城市：">
            <city-select
              ref="child"
              :province="queryInfo.province"
              :city="queryInfo.city"
              :district="queryInfo.district"
              @searchProvince="searchProvince"
              @searchCity="searchCity"
              @searchDistrict="searchDistrict"
            />
          </el-form-item>

          <!-- 底部 -->
          <el-form-item label=" " label-width="0" style="margin-bottom: 0">
            <div class="newClue">
              <div class="input_flex">
                <el-button style="margin-right: 5px">批量删除</el-button>

                <el-button style="margin-right: 5px">批量转化线索</el-button>
                <el-button style="margin-right: 5px">批量推送</el-button>

                <el-checkbox
                  style="position: relative; top: 5px"
                  :indeterminate="isIndeterminate"
                  v-model="checkAll"
                  @change="handleCheckAllChange"
                  >全选</el-checkbox
                >
              </div>
              <!-- 右边搜索 -->
              <div class="search_right">
                <el-input
                  placeholder="请输入线索标题、企业名称"
                  v-model="queryInfo.keys"
                  class="input-with-select"
                  style="width: 500px"
                  clearable
                  @change="changeSearchangeSearch"
                >
                  <el-button slot="append" @click="changeSearchangeSearch">搜索</el-button>
                </el-input>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </el-card>
      <div style="display: flex; align-items: center; padding: 10px 0px">
        <div style="padding-right: 50px">
          <span>当前条件下共查出{{ total }}潜在条线索</span>
        </div>

        <div style="display: flex; align-items: center; margin-right: 30px">
          按匹配分数
          <div class="main">
            <span
              class="arrUp"
              @click="clickUp5"
              :style="{ 'border-bottom-color': orderinfo.color5 }"
            ></span>
            <span
              class="arrDown"
              @click="clickDown6"
              :style="{ 'border-top-color': orderinfo.color6 }"
            ></span>
          </div>
        </div>
      </div>
      <!-- 卡片列表 -->
      <div class="card_list" v-loading="loading">
        <div class="clue_card" v-for="(item, index) in datalist" :key="index">
          <div class="card_left">
            <!-- 企业图片区域 -->
            <div class="clue_img">
              <img
                src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-925863328Group 1142814580.png"
                alt="图片"
              />
            </div>
            <!-- 企业信息 -->
            <div class="card_info">
              <div class="row">
                <div>
                  <span class="clue_name">潜在线索名称</span>
                </div>
                <div class="card_info_flex">
                  <div class="type_culb">湖南科技有限公司</div>
                  <div class="type_culb_city">
                    <span>
                      所在地区：{{ item.province }}-{{ item.city }}
                    </span>
                  </div>
                </div>
                <div class="bottom_row">
                  <span>匹配时间：{{ item.clueStartTime }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 数据统计 -->
          <div class="count_list">
            <div class="col first_col" @click="serviceBtn(item)">
              <div class="row">{{ item.serviceNum || 0 }}</div>
              <div>
                <span>关联合作伙伴</span>
              </div>
            </div>
            <div class="col" @click="peopleClick(item)">
              <div class="row">{{ item.specialistNum || 0 }}</div>
              <div>
                <span>匹配分数</span>
              </div>
            </div>
          </div>

          <!-- 右侧按钮 -->
          <div class="card_right">
            <div class="btn_list row">
              <span class="click" style="color: #ffbc06" @click="digClick(item)">服务建议</span>
              <span class="click" style="color: #4e93fb">推送</span>

              <span class="click" style="color: #4e93fb">转化线索</span>
              <span class="click" style="color: #4e93fb" @click="infoClick(item)">详情</span>

              <span class="frozen">删除</span>
            </div>
            <div class="row_bottom">
              <span>数据来源：数智报告与智参企业算法匹配</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 分页区域 -->
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[4, 10, 30]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog title="服务建议" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div class="refuse">
        <el-form
          :model="refuseObj"
           :rules="rules"
          ref="ruleForm"
          label-width="80px"
          class="demo-ruleForm"
        >
          <el-form-item label="建议描述" prop="refuseReason">
            <el-input
              type="textarea"
              :rows="10"
              placeholder="请输入"
              v-model="refuseObj.refuseReason"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="handleClose">返回</el-button>
      
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import CitySelect from '@/components/CitySelect.vue'
import {
  serverApiClueList,
  notFreeze,
  updateStage,
  listCompanyId,
  transformBusinessOpportunity,
  saveNumber,
  downloadPdf,
  downloadAnalysisPdf
} from '@/api/clue.js'
import { getCode } from '@/api/demand.js'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  orders: 1, // 排序 默认传1 2->按创建时间升序;3->按创建时间降序;4->按押金升序;5->按押金降序;6->按线索费升序;7->按线索费降序8->按查看升序;9->按查看降序;10->按分享升序;11->按分享降序;12->按推送升序;13->按推送降序
  city: '', //城市
  province: '', //省份
  keys: '', //线索标题/企业名称
  clueState: '', //线索状态 1->邀约中;2->进行中;3->已完结;4->已终止
  clueStage: '', // 线索阶段
  clueType: '', //线索类型
  district: '', // 区县
  clueResource: '' // 线索来源
})
const defaultOrder = Object.freeze({
  color1: '',
  color2: '#C6C6C6',
  color3: '',
  color4: '#C6C6C6',
  color5: '',
  color6: '#C6C6C6',
  color7: '',
  color8: '#C6C6C6',
  color9: '',
  color10: '#C6C6C6',
  color11: '',
  color12: '#C6C6C6'
})

export default {
  components: { CitySelect },
  name: 'clue',
  data() {
    return {
      ids: [],
      rules: {
        refuseReason: [{ required: true, message: '请输入', trigger: 'blur' }]
      }, //提交理由的验证表单
      idsList: [],
      refuseObj:{},
      checkAll: false, //全选
      isIndeterminate: false,
      //查询参数
      queryInfo: { ...defaultQueryInfo },
      total: 0,
      //排序字段
      orderinfo: { ...defaultOrder },
      //线索卡片列表
      datalist: [{}, {}],
      //线索类型
      typeList: [],
      dialogVisible:false,
      //线索阶段
      stageList: [],
      //线索状态
      clueStateList: [
        {
          id: '',
          val: '全部'
        },
        {
          id: 1,
          val: '正常'
        },
        {
          id: 2,
          val: '冻结'
        },
        {
          id: 3,
          val: '完成'
        }
      ],
      menuRules: {
        clueStage: [{ required: true, message: '请选择线索阶段', trigger: 'change' }]
      },

      loading: false
    }
  },
  created() {
    // this.search()
  },
  computed: {},
  methods: {
    //请求数据
    async search() {
      this.loading = true

      this.loading = false
    },
    //选择活动时间
    checkParamsData(val) {
      if (val) {
        this.queryInfo.pageNum = 1
        this.queryInfo.activityStartTime = val[0]
        this.queryInfo.activityEndTime = val[1]
      } else {
        this.queryInfo.pageNum = 1
        this.queryInfo.activityStartTime = ''
        this.queryInfo.activityEndTime = ''
      }

      this.search()
    },
    handleClose(){
        this.dialogVisible=false
    },
    digClick(){
        this.dialogVisible=true
    },
    //全选
    handleCheckAllChange(val) {
      if (val) {
        this.listData.forEach((item) => {
          this.ids.push(item.id)
          this.idsList.push(item)
          this.$set(item, 'checked1', true)
        })
      } else {
        this.listData.forEach((item) => {
          this.ids = []
          this.idsList = []
          this.$set(item, 'checked1', false)
        })
      }
      this.isIndeterminate = val
    },
    infoClick(item){
        this.$router.push({
            path: '/latentclue/info',
       
      })
    },
    //返回省
    searchProvince(val) {
      this.queryInfo.pageNum = 1
      this.queryInfo.province = val
      this.search()
    },
    //返回城市
    searchCity(val) {
      this.queryInfo.city = val
      this.search()
    },
    searchDistrict(val) {
      this.queryInfo.district = val
      this.search()
    },
    //排序按创建时间
    clickUp1() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '1'
      this.search()
      this.orderinfo.color1 = '#0099ff'
      this.orderinfo.color2 = ''
    },
    clickDown2() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '2'
      this.search()
      this.orderinfo.color1 = ''
      this.orderinfo.color2 = '#0099ff'
    },
    //排序按等级
    clickUp3() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '3'
      this.search()
      this.orderinfo.color3 = '#0099ff'
      this.orderinfo.color4 = ''
    },
    clickDown4() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '4'
      this.search()
      this.orderinfo.color3 = ''
      this.orderinfo.color4 = '#0099ff'
    },
    //排序按线分数
    clickUp5() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '5'
      this.search()

      this.orderinfo.color5 = '#0099ff'
      this.orderinfo.color6 = ''
    },
    clickDown6() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '6'
      this.search()

      this.orderinfo.color5 = ''
      this.orderinfo.color6 = '#0099ff'
    },

    showTags(i) {
      this.tagIndex = i
      this.isShowTags = true
    },
    hideTags() {
      this.isShowTags = false
    },

    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.search()
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>
  
  <style lang="less" scoped>
.clue {
  overflow: hidden;
  .container {
    height: calc(100vh - 170px);
    overflow: auto;
    .input_flex {
      display: flex;
      align-items: center;
    }
  }
  ::v-deep .el-radio-button__inner {
    border: none;
    margin-right: 12px;
    border-radius: 4px;
  }
}
.newClue {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
}
.search_right {
  width: 500px;
  margin-right: 40px;
  ::v-deep .el-input-group__append {
    border-right: 0;
  }
  ::v-deep .el-button {
    border: #448aff 1px solid;
    color: #fff;
    background-color: #448aff;
  }
}
.main {
  display: flex;
  flex-direction: column;
}

.arrUp {
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #c6c6c6;
  cursor: pointer;
}

.arrDown {
  width: 0;
  height: 0;
  margin-top: 2px;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-top: 8px solid #c6c6c6;
  cursor: pointer;
}

.top_card {
  margin-bottom: 10px;
}
.card_list {
  width: 100%;
  
}
.clue_card {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  background: #ffffff;
  padding: 20px 20px;
  box-sizing: border-box;
}

.card_left {
  display: flex;
  width: 30%;
  .clue_img {
    flex-shrink: 0;
    position: relative;
    width: 70px;
    height: 70px;
    margin-right: 10px;
    border-radius: 1px;
    img {
      width: 70px;
      height: 70px;
      border-radius: 2px;
    }
  }

  .card_info {
    flex: 1;

    .row {
      margin-bottom: 10px;
    }
    .clue_name {
      font-size: 17px;
      font-weight: bolder;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      min-width: 40px;
      max-width: 200px;
      -webkit-box-orient: vertical;
    }
    .card_info_flex {
      display: flex;
      align-items: center;
    }
    .type_culb {
        padding: 8px 0px;
        box-sizing: border-box;
        border-radius: 4px;
        color: #4e93fb;
        font-size: 14px;
        font-weight: 400;
       
      }
      .type_culb_city{
        margin-left: 10px;
        font-size: 14px;
      }
      .bottom_row{
        font-size: 14px;
      }
  }

 
}

.count_list {
    width: 30%;
  display: flex;
  padding: 10px;
  .row {
    color: #078bff;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .col {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 20px;
  }
  :hover span {
    color: #4e93fb;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}

.card_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 20%;
  .frozen {
    color: #fd5469;
    cursor: pointer;
  }
  .row_bottom {
    background: #4593ef;
    padding: 5px;
    color: #fff;
    font-size: 14px;
    position: absolute;
    bottom: -20px;
    right: 0px;
  }
  .btn_list {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    position: relative;
    top: 20px;
    margin-bottom: 30px;
    .click {
      cursor: pointer;
    }
  }
  .operate {
    display: flex;
    justify-content: right;
    font-size: 10px;
    color: #999;
    margin-bottom: 15px;
  }
}
.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}
.popover_text {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  .el-icon-download {
    font-size: 20px;
  }
}
.my-custom-class {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 18px;
}
</style>
  